var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isDisplayGraph ? _c("Loader") : _vm._e(),
      _c("div", { staticClass: "page-container" }, [
        _c("div", { staticClass: "page-wrapper" }, [
          _c("div", { staticClass: "chart-card" }, [
            _c("div", { staticClass: "title-container" }, [
              _vm._m(0),
              _c("div", { staticClass: "projected-completion-date" }, [
                _c("div", { staticClass: "projected-date" }, [
                  _vm._v(_vm._s(_vm.projectCompletionDate)),
                ]),
                _c("div", { staticClass: "content-label" }, [
                  _vm._v("Projected Completion Date"),
                ]),
              ]),
            ]),
            _c("div", {
              staticStyle: { height: "542px" },
              attrs: { id: "graphContainer" },
            }),
            _c("div", { staticClass: "xAxis-label" }, [_vm._v("Weeks")]),
            _vm._m(1),
          ]),
          _c("div", { staticClass: "chart-card" }, [
            _c("div", { staticClass: "title-container" }, [
              _vm._m(2),
              _c("div", { staticClass: "team-efficiency-container" }, [
                _c("div", { staticClass: "team-efficiency-wrap" }, [
                  _c("div", { staticClass: "div-text" }, [
                    _vm._v(_vm._s(_vm.teamEfficiency) + "%"),
                  ]),
                  _c("div", { staticClass: "div-label" }, [
                    _vm._v("Efficiency"),
                  ]),
                ]),
                _c("div", { staticClass: "team-velocity-wrap" }, [
                  _c("div", { staticClass: "div-text" }, [
                    _vm._v(_vm._s(_vm.teamSpeed) + "x%"),
                  ]),
                  _c("div", { staticClass: "div-label" }, [
                    _vm._v(_vm._s(_vm.teamMovement)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", {
              staticStyle: { height: "542px" },
              attrs: { id: "teamvelocityContainer" },
            }),
            _c("div", { staticClass: "xAxis-label" }, [_vm._v("Sprints")]),
            _vm._m(3),
          ]),
        ]),
        _c("div", { staticClass: "page-wrapper" }, [
          _c("div", { staticClass: "chart-card" }, [
            _c("div", { staticClass: "title-container" }, [
              _vm._m(4),
              _c("div", { staticClass: "average-rating-div" }, [
                _vm.averageRating
                  ? _c("div", { staticClass: "average-rating-score" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.averageRating.toFixed(2)) +
                          "/5\n            "
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "content-label" }, [
                  _vm._v("Average Rating"),
                ]),
              ]),
            ]),
            _c("div", {
              staticStyle: { height: "542px" },
              attrs: { id: "customerSatisfaction" },
            }),
            _c("div", { staticClass: "xAxis-label" }, [_vm._v("Weeks")]),
            _vm._m(5),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inner-container" }, [
      _c("div", { staticClass: "chart-title" }, [
        _vm._v("SPRINT BURNUP CHART"),
      ]),
      _c("div", { staticClass: "iteraton-number" }, [_vm._v("Iteration 1")]),
      _c("div", { staticClass: "sprint-number" }, [_vm._v("All Sprints")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "graph-description" }, [
      _c("div", { staticClass: "graph-label" }, [
        _c("div", { staticClass: "color-point original-plan" }),
        _c("div", { staticClass: "label-name" }, [_vm._v("Original Plan")]),
      ]),
      _c("div", { staticClass: "graph-label" }, [
        _c("div", { staticClass: "color-point projected" }),
        _c("div", { staticClass: "label-name" }, [_vm._v("Projected")]),
      ]),
      _c("div", { staticClass: "graph-label" }, [
        _c("div", { staticClass: "dashed-black total-task" }),
        _c("div", { staticClass: "label-name" }, [
          _vm._v("Total Tasks Planned"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inner-container" }, [
      _c("div", { staticClass: "chart-title" }, [
        _vm._v("TEAM VELOCITY & EFFICIENCY"),
      ]),
      _c("div", { staticClass: "iteraton-number" }, [_vm._v("Iteration 1")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "graph-description" }, [
      _c("div", { staticClass: "graph-label" }, [
        _c("div", { staticClass: "color-point ideal-velocity" }),
        _c("div", { staticClass: "label-name" }, [_vm._v("Ideal Velocity")]),
      ]),
      _c("div", { staticClass: "graph-label" }, [
        _c("div", { staticClass: "color-point actual-velocity" }),
        _c("div", { staticClass: "label-name" }, [_vm._v("Actual Velocity")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "inner-container" }, [
      _c("div", { staticClass: "chart-title" }, [
        _vm._v("CUSTOMER SATISFACTION INDEX"),
      ]),
      _c("div", { staticClass: "iteraton-number" }, [_vm._v("Iteration 1")]),
      _c("div", { staticClass: "sprint-number" }, [_vm._v("All Sprints")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "graph-description" }, [
      _c("div", { staticClass: "graph-label" }, [
        _c("div", { staticClass: "color-point original-plan" }),
        _c("div", { staticClass: "label-name" }, [
          _vm._v("Weekly ratings by Product Owner"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <Loader v-if="!isDisplayGraph" />
    <div class="page-container">
      <div class="page-wrapper">
        <div class="chart-card">
          <div class="title-container">
            <div class="inner-container">
              <div class="chart-title">SPRINT BURNUP CHART</div>
              <div class="iteraton-number">Iteration 1</div>
              <div class="sprint-number">All Sprints</div>
            </div>
            <div class="projected-completion-date">
              <div class="projected-date">{{ projectCompletionDate }}</div>
              <div class="content-label">Projected Completion Date</div>
            </div>
          </div>
          <div id="graphContainer" style="height: 542px"></div>
          <div class="xAxis-label">Weeks</div>
          <div class="graph-description">
            <div class="graph-label">
              <div class="color-point original-plan"></div>
              <div class="label-name">Original Plan</div>
            </div>
            <!-- <div class="graph-label">
              <div class="color-point original-plan"></div>
              <div class="label-name">Actual Progress</div>
            </div> -->
            <div class="graph-label">
              <div class="color-point projected"></div>
              <div class="label-name">Projected</div>
            </div>
            <div class="graph-label">
              <div class="dashed-black total-task"></div>
              <div class="label-name">Total Tasks Planned</div>
            </div>
          </div>
        </div>
        <div class="chart-card">
          <div class="title-container">
            <div class="inner-container">
              <div class="chart-title">TEAM VELOCITY & EFFICIENCY</div>
              <div class="iteraton-number">Iteration 1</div>
            </div>
            <div class="team-efficiency-container">
              <div class="team-efficiency-wrap">
                <div class="div-text">{{ teamEfficiency }}%</div>
                <div class="div-label">Efficiency</div>
              </div>
              <div class="team-velocity-wrap">
                <div class="div-text">{{ teamSpeed }}x%</div>
                <div class="div-label">{{ teamMovement }}</div>
              </div>
            </div>
          </div>
          <div id="teamvelocityContainer" style="height: 542px"></div>
          <div class="xAxis-label">Sprints</div>
          <div class="graph-description">
            <div class="graph-label">
              <div class="color-point ideal-velocity"></div>
              <div class="label-name">Ideal Velocity</div>
            </div>
            <div class="graph-label">
              <div class="color-point actual-velocity"></div>
              <div class="label-name">Actual Velocity</div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-wrapper">
        <div class="chart-card">
          <div class="title-container">
            <div class="inner-container">
              <div class="chart-title">CUSTOMER SATISFACTION INDEX</div>
              <div class="iteraton-number">Iteration 1</div>
              <div class="sprint-number">All Sprints</div>
            </div>
            <div class="average-rating-div">
              <div v-if="averageRating" class="average-rating-score">
                {{ averageRating.toFixed(2) }}/5
              </div>
              <div class="content-label">Average Rating</div>
            </div>
          </div>
          <div id="customerSatisfaction" style="height: 542px"></div>
          <div class="xAxis-label">Weeks</div>
          <div class="graph-description">
            <div class="graph-label">
              <div class="color-point original-plan"></div>
              <div class="label-name">Weekly ratings by Product Owner</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as echarts from "echarts";
import appMixin from "@/mixins/appMixin";
import Loader from "@/components/general/centerLoader";
import { getSprintBurn, teamVelocity, customerSatisfaction } from "@/api";
import {
  formatDayMonth,
  businessDaysDiffInDays,
  addDaysFromDate,
} from "@/utils/dateHelpers";
export default {
  mixins: [appMixin],
  props: {},
  components: {
    Loader,
  },

  data: () => ({
    isDisplayGraph: false,
    allSprint: null,
    completedSprint: null,
    expectedSprint: null,
    averageData: {},
    sprintArray: null,
    velocityArray: [],
    teamEfficiency: "",
    teamSpeed: "",
    teamMovement: "",
    iterationSprints: [],
    projectCompletionDate: "",
    customerRating: [
      {
        created_at: "2021-08-31 19:07:15",
        id: 1,
        iteration_id: 242,
        sprint_id: 733,
        empathy: 4,
        professionalism: 4,
        responsiveness: 4,
        communication: 3,
      },
      {
        created_at: "2021-09-06 19:07:15",
        id: 1,
        iteration_id: 242,
        sprint_id: 734,
        empathy: 3,
        professionalism: 3,
        responsiveness: 3,
        communication: 4,
      },
      {
        created_at: "2021-09-13 19:07:15",
        id: 1,
        iteration_id: 242,
        sprint_id: 735,
        empathy: 4,
        professionalism: 4,
        responsiveness: 4,
        communication: 3,
      },
    ],
    averageRating: null,
  }),
  created() {
    this.fetchSprintBurnUp();
    this.fetchTeamVelocityData();
    this.getCustomerSatisfaction();
  },
  mounted() {},
  methods: {
    fetchSprintBurnUp() {
      let iterationId = this.project.current_iteration;
      getSprintBurn(iterationId).then((response) => {
        this.isDisplayGraph = true;
        let { average_data, data } = response.data.data;
        this.averageData = average_data;
        this.iterationSprints = average_data.all_ideal_tasks;
        this.sprintArray = data;
        // if (this.isDisplayGraph) {
        this.computeSprintBurnUp();
        // }
      });
    },
    computeSprintBurnUp() {
      let sprintArray = this.sprintArray;
      let {
        average_task_completed,
        total_completed_task,
        total_number_of_sprint,
        total_iteration_tasks,
      } = this.averageData;
      let undoneTasks = total_iteration_tasks - total_completed_task;
      if (sprintArray.length > 0) {
        const avgCompletedTasks = this.getAvgCompletedTasks(
          sprintArray,
          total_completed_task
        );
        let absAvgCompletedTasks = Math.round(avgCompletedTasks);
        let estimatedWeekLeft = Math.round(undoneTasks / avgCompletedTasks);
        let lastSprint = sprintArray[sprintArray.length - 1];
        let currentToDate = lastSprint.current_cumulative_count;
        let lastSprintDate = lastSprint.sprint_mark_as_completed_date;
        let addDays = 0;
        for (let i = 1; i <= estimatedWeekLeft; i++) {
          currentToDate += absAvgCompletedTasks;
          addDays += 7;
          // Exceptional cases
          // This should be reviewed later
          if (currentToDate > total_iteration_tasks) {
            currentToDate = total_iteration_tasks;
          }
          let newColumn = {
            completed_task: absAvgCompletedTasks,
            current_cumulative_count: currentToDate,
            sprint_mark_as_completed_date: addDaysFromDate(
              lastSprintDate,
              addDays
            ),
          };
          sprintArray.push(newColumn);
        }

        let completionDate =
          sprintArray[sprintArray.length - 1].sprint_mark_as_completed_date;
        this.projectCompletionDate =
          this.$moment(completionDate).format("MMM, DD,YYYY");
        this.createSprintBurnUpGraph(sprintArray, total_iteration_tasks);
      }
    },
    fetchTeamVelocityData() {
      let iterationId = this.project.current_iteration;
      teamVelocity(iterationId).then((response) => {
        this.isDisplayGraph = true;
        this.velocityArray = response.data.data;
        // if (this.isDisplayGraph == true) {
        this.plotTeamVelocityGraph();
        // }
      });
    },
    handleTeamSpeed(ideal, actual) {
      if (ideal > actual) {
        this.teamSpeed = (ideal / actual).toFixed(2);
        this.teamMovement = "Slower";
      } else if (ideal < actual) {
        this.teamSpeed = (actual / ideal).toFixed(2);
        this.teamMovement = "Faster";
      }
    },
    createSprintBurnUpGraph(data, totalTask) {
      let cumulativeTaskCompleted = [0];
      let cumulativeTaskPerWeek = this.getTasksPlanned();
      let totalNumberOfTask = [totalTask];
      let horizontalDate = [0];
      let currentState = [0];
      let initialTask = 0;
      for (let i = 0; i < data.length; i++) {
        cumulativeTaskCompleted.push(data[i].current_cumulative_count);
        horizontalDate.push(
          formatDayMonth(data[i].sprint_mark_as_completed_date)
        );
        totalNumberOfTask.push(totalTask);
        if (data[i].sprint_id) {
          initialTask += data[i].completed_task;
          currentState.push(initialTask);
        }
      }
      let chartDom = document.getElementById("graphContainer");
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        toolbox: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
          height: "500px",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: horizontalDate,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Original Plan",
            type: "line",
            smooth: true,
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: cumulativeTaskPerWeek,
            color: ["#6FB5F6"],
          },
          {
            name: "Projected",
            type: "line",
            smooth: true,
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: cumulativeTaskCompleted,
            color: ["#54BDD4"],
          },
          {
            name: "Actual Progress",
            type: "line",
            smooth: true,
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: currentState,
            color: ["#54CF94"],
          },
          {
            name: "Total Tasks Planned",
            type: "line",
            smooth: true,
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: totalNumberOfTask,
            color: ["#ffffff"],
            lineStyle: {
              type: "dotted",
              color: ["#1B1E22"],
              width: 2,
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
    formulateXAxis(leng) {
      let computeArray = [];
      for (var i = 0; i < leng; i++) {
        computeArray.push(i);
      }
      return computeArray;
    },
    computeXAxis(leng) {
      let computeArray = [];
      for (var i = 1; i <= leng; i++) {
        computeArray.push("Spr" + i);
      }
      return computeArray;
    },
    plotTeamVelocityGraph() {
      let idealVelocity = [];
      let actualVelocity = [];
      this.velocityArray.map((velocity) => {
        idealVelocity.push(velocity.ideal_velocity);
        if (velocity.actual_velocity !== 0) {
          actualVelocity.push(velocity.actual_velocity);
        }
      });

      let averageIdealVelocity =
        idealVelocity.reduce((a, b) => a + b, 0) / idealVelocity.length;
      let averageActualVelocity =
        actualVelocity.reduce((a, b) => a + b, 0) / actualVelocity.length;
      this.teamEfficiency = (
        (averageActualVelocity / averageIdealVelocity) *
        100
      ).toFixed(2);

      this.handleTeamSpeed(averageIdealVelocity, averageActualVelocity);

      let chartDom = document.getElementById("teamvelocityContainer");
      let myChart = echarts.init(chartDom);
      let option;

      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        toolbox: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
          height: "500px",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.computeXAxis(idealVelocity.length),
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Ideal Velocity",
            type: "line",
            smooth: true,
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: idealVelocity,
            color: ["rgba(84, 207, 148, 0.261)"],
            lineStyle: {
              type: "solid",
              width: 2,
              color: ["#6FB5F6"],
            },
          },
          {
            name: "Actual Velocity",
            type: "line",
            smooth: true,
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: actualVelocity,
            color: ["rgba(84, 207, 148, 0.261)"],
            lineStyle: {
              type: "solid",
              width: 2,
              color: ["#54CF94"],
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
    plotSprintFeedback() {
      let chartDom = document.getElementById("customerSatisfaction");
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        toolbox: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
          height: "500px",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.computeCustomerSatisfaction().horizontal_axis,
          },
        ],
        yAxis: [
          {
            type: "value",
            // min: 5,
            max: 5,
          },
        ],
        series: [
          {
            name: "Score",
            type: "line",
            smooth: true,
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: this.computeCustomerSatisfaction().vetical_axis,
            // color: ["rgba(111, 181, 246, 0.5)"],
            itemStyle: {
              color: ["rgba(111, 181, 246, 0.5)"],
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0.26,
                  color: ["rgba(111, 181, 246, 0.5)"],
                },
                {
                  offset: 0.71,
                  color: ["rgba(111, 181, 246, 0)"],
                },
              ]),
            },
            lineStyle: {
              type: "solid",
              width: 2,
              color: ["#6FB5F6"],
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
    getAvgCompletedTasks(data, totalCompletedTask) {
      let numberOfDaysSpent = 0;
      data.map((sprint) => {
        let dateDiff = businessDaysDiffInDays(
          sprint.sprint_mark_as_completed_date,
          sprint.sprint_start_date
        );
        if (dateDiff < 1) {
          numberOfDaysSpent += 1;
        } else {
          numberOfDaysSpent += dateDiff;
        }
      });
      const daysToWeek = numberOfDaysSpent / 5;
      return totalCompletedTask / daysToWeek;
    },
    getTasksPlanned() {
      let data = this.iterationSprints;
      let sprintTasks = [0];
      let initialTask = 0;
      data.map((sprint) => {
        initialTask += sprint.length;
        sprintTasks.push(initialTask);
      });
      return sprintTasks;
    },
    async getCustomerSatisfaction() {
      let iterationId = this.project.current_iteration;
      await customerSatisfaction(iterationId)
        .then((resp) => {
          this.isDisplayGraph = true;
          this.customerRating = resp.data.data;
          this.plotSprintFeedback();
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
    computeCustomerSatisfaction() {
      let ratings = this.customerRating;
      let horizontal_axis = [];
      if (ratings.length > 0) {
        let vetical_axis = ratings.map((rating) => {
          let average =
            rating.professionalism +
            rating.empathy +
            rating.responsiveness +
            rating.communication;
          const createAt = this.$moment(rating.created_at).format("MMM DD");
          horizontal_axis.push(createAt);
          return average / 4;
        });
        this.averageRating = this.findAverage(vetical_axis);
        return {
          horizontal_axis,
          vetical_axis,
        };
      } else {
        return {
          horizontal_axis: [],
          vetical_axis: [],
        };
      }
    },
    findAverage(arrayNum) {
      return arrayNum.reduce((a, b) => a + b, 0) / arrayNum.length;
    },
  },
  computed: {
    ...mapState("devDashboard", ["selectedProjectDoc", "selectedProject"]),
    project() {
      return this.selectedProject;
    },
    currentIteration() {
      return this.selectedProjectDoc.iterations.find(
        (itr) => itr.id == this.selectedProject.current_iteration
      );
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.chart-card {
  width: 49%;
  background: #ffffff;
  border-radius: 4px;
  margin-right: 12px;
  position: relative;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-bottom: 16px;
}
.chat-label-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}
.graph-description {
  display: flex;
  margin-top: 24px;
  padding-left: 24px;
}
.graph-label {
  display: flex;
  margin-right: 16px;
}
.color-point {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.graph-label .ideal-velocity,
.graph-label .original-plan {
  background: #6fb5f6;
}
.graph-label .actual-velocity {
  background: #54cf94;
}
.graph-label .projected {
  background: #54bdd4;
}

.graph-label .total-task {
  background: #1b1e22;
  width: 24px;
  height: 2px;
  margin-right: 1px;
  margin-top: 4px;
}
.label-name {
  font-size: 10px;
  line-height: 12px;
  color: #979da5;
  margin-left: 4px;
}
.xAxis-label {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  font-size: 10px;
  line-height: 12px;
  color: #c8cbd0;
}
/* Echarts */
.chart-wrapper {
  width: 100%;
  height: 700px;
}
.title-container {
  display: flex;
  padding-left: 24px;
  justify-content: space-between;
}
.inner-container {
  display: flex;
}
.chart-title {
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #979da5;
}
.sprint-number,
.iteraton-number {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-left: 12px;
  border-left: 1px solid #e4e5e7;
  padding-left: 12px;
  height: 18px;
}
.average-rating-score,
.projected-date {
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: #de9221;
}
.content-label {
  font-size: 10px;
  line-height: 12px;
  color: #979da5;
}
.team-efficiency-container {
  padding-right: 24px;
  display: flex;
}
.team-efficiency-wrap .div-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: #54cf94;
}
.team-efficiency-wrap {
  border-right: 1px solid #e4e5e7;
  padding-right: 12px;
}
.team-velocity-wrap .div-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: #de9221;
}
.team-velocity-wrap {
  padding-left: 12px;
}
.average-rating-div,
.projected-completion-date {
  padding-right: 24px;
  text-align: right;
}
.team-velocity-wrap .div-label,
.team-efficiency-wrap .div-label {
  font-size: 10px;
  line-height: 12px;
  color: #979da5;
}
@media (max-width: 1280px) {
  .chart-card {
    margin-right: 6px;
    width: 48%;
  }
}
@media (max-width: 1440px) {
  .chart-card {
    margin-right: 6px;
    width: 48%;
  }
}
</style>
